<template>
  <div class="about">
    <Header />
    <Policy />
    <div class="banner">
      <h1>{{ $t("aboutView.h1") }}</h1>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
    >
      <path
        class="elementor-shape-fill"
        opacity="0.33"
        d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
      ></path>
      <path
        class="elementor-shape-fill"
        opacity="0.66"
        d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
      ></path>
      <path
        class="elementor-shape-fill"
        d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
      ></path>
    </svg>
    <div class="container-0">
      <div class="text">
        <h2>{{ $t("aboutView.h21") }}</h2>
        <p>{{ $t("aboutView.p1") }}</p>
      </div>
    </div>
    <div class="content">
      <div class="container-1">
        <div class="text">
          <h2>{{ $t("aboutView.h22") }}</h2>
          <p>{{ $t("aboutView.p2") }}<br /><br /></p>
        </div>
      </div>
      <div class="container-2">
        <div class="text">
          <h2>{{ $t("aboutView.h23") }}</h2>
          <p>{{ $t("aboutView.p3") }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container1">
        <div class="text">
          <h2>{{ $t("aboutView.h24") }}</h2>
          <img
            id="image-mobile"
            src="@/assets/images/biography.jpg"
            alt="image"
          />
          <h4>{{ $t("aboutView.h25") }}</h4>
          <p>{{ $t("aboutView.p4") }}</p>
          <div class="social">
            <a
              href="https://www.facebook.com/isabel.marques.127648"
              target="https://www.facebook.com/isabel.marques.127648"
              ><fa-icon id="facebook" :icon="['fab', 'facebook']"
            /></a>
            <a
              href="https://instagram.com/prof.isabelmarques?utm_medium=copy_link"
              target="https://instagram.com/prof.isabelmarques?utm_medium=copy_link"
              ><fa-icon id="instagram" :icon="['fab', 'instagram']"
            /></a>
            <a
              href="https://wa.me/5521994689987"
              target="https://wa.me/5521994689987"
              ><fa-icon id="whatsapp" :icon="['fab', 'whatsapp']"
            /></a>
            <a
              href="https://wa.me/5521994689987"
              target="https://wa.me/5521994689987"
              ><fa-icon id="whatsapp" :icon="['fab', 'mail']"
            /></a>
          </div>
        </div>
        <img
          id="image-desktop"
          src="@/assets/images/biography.jpg"
          alt="image"
        />
      </div>
    </div>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";
export default {
  components: {
    Header,
    Contact,
    Feedback,
    Footer,
    Policy,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.about > svg {
  position: absolute;
  top: 440px;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  transform: rotateX(-180deg);
  fill: #fff;
}
#arrow {
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  animation: effect 2s ease-in-out infinite;
}
@keyframes effect {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
.banner {
  position: relative;
  height: 410px;
  width: 100%;
  background: linear-gradient(rgb(255, 155, 19, 0.8), rgb(255, 155, 19, 0.8)),
    url("../assets/images/rio2.jpg");
  background-size: cover;
  background-position: center;
  background-position-y: -400px;
  position: fixed;
  z-index: -1 ;
}
.banner h1 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #fff;
  text-align: center;
  padding: 20px;
  font-weight: 800;
}
.title {
  padding: 10px 80px;
  background: #fff;
  color: #333333;
}
.title h1 {
  font-size: 35px;
  font-weight: bold;
  font-family: 'ubuntu', sans-serif;
}
.title p {
  font-size: 16px;
  color: #9e9e9e;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.container-0 {
  padding: 50px 80px;
  background: #fff;
  color: #333333;
  margin-top: 410px;
}
.container-1,
.container-2 {
  padding: 50px 80px;
  text-align: justify;
  border-top: solid 3px #ff9b13;
  border-bottom: solid 3px #ff9b13;
  color: #333333;
  height: 300px;
  width: 50%;
}
.container-1 {
  margin: 0 20px 0 100px;
}
.container-2 {
  margin: 0 100px 0 20px;
}
.container-0 .text h2,
.container-1 .text h2,
.container-2 .text h2 {
  font-size: 20px;
  font-weight: 800;
  font-family: 'ubuntu', sans-serif;
  padding-bottom: 30px;
  text-align: left;
  color: #ff9b13;
}
.container1 {
  width: 100%;
  background: transparent;
  color: #333333;
  padding: 50px 80px;
  text-align: justify;
  margin: 0 20px 20px 20px;
  /* box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.4); */
  border-radius: 20px;
  display: flex;
}
.container1 h2 {
  font-size: 20px;
  font-family: 'ubuntu', sans-serif;
  padding-bottom: 40px;
  text-align: left;
  font-weight: 800;
  color: #ff9b13;
}
.container1 #image-desktop {
  width: 300px;
  height: 300px;
  border-radius: 0;
  margin-left: 30px;

  object-fit: cover;
  object-position: center;
}
.container1 #image-mobile {
  width: 100%;
  padding-bottom: 30px;
  display: none;
}
.container1 h4 {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 20px;
}
.container1 p {
  padding-bottom: 20px;
}
.social {
  display: flex;
  justify-content: flex-start;
}
.social a {
  margin-right: 20px;
  font-size: 40px;
  color: #333333;
}
#facebook:hover {
  color: #0f90f2;
}
#instagram:hover {
  color: #ba4578;
}
#whatsapp:hover {
  color: #2ad249;
}

@media (max-width: 1000px) {
  .banner {
    position: fixed;
    height: 350px;
    width: 100%;
    background: linear-gradient(rgb(255, 155, 19, 0.8), rgb(255, 155, 19, 0.8)),
      url("../assets/images/rio2.jpg");
    background-size: cover;
    background-position: center;
  }
  .about .banner h1 {
    font-size: 40px;
  }
  .about img {
    width: 350px;
  }
  .about svg {
    top: 410px;
    height: 20px;
  }
  .title > h1 {
    font-size: 60px;
  }
  #arrow {
    top: 70%;
  }
  .content {
    flex-direction: column;
    width: 100%;
    background: #fff;
  }
  .container-0 {
    margin-top: 349px;
    padding: 30px 10px 10px 10px;
    text-align: justify;
    background: #fff;
  }
  .container-0 .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    box-shadow: 0 0 10px -5px rgba(0, 0, 0);
    border-radius: 20px;
  }
  .text h2 {
    text-align: center;
  }
  .container-1,
  .container-2 {
    width: initial;
    margin: 10px;
    padding: 50px 20px;
    border: none;
    border-radius: 20px;
    background: #fff;
    color: #333333;
    box-shadow: 0 0 10px -5px rgba(0, 0, 0);
  }
  .content .container-1 h2,
  .content .container-2 h2 {
    text-align: center;
    color: #ff9b13;
  }
  .container1 {
    width: initial;
    padding: 50px 20px;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px -5px rgba(0, 0, 0);
  }
  .container1 h2,
  .container1 h4 {
    text-align: center;
  }
  .container1 #image-mobile {
    display: block;
  }
  .container1 #image-desktop {
    display: none;
  }
  #facebook,
  #instagram,
  #whatsapp {
    width: 40px;
    height: 40px;
  }
  #facebook {
    color: #0f90f2;
  }
  #instagram {
    color: #ba4578;
  }
  #whatsapp {
    color: #2ad249;
  }
}
@media (max-width: 640px) {
  .banner h1 {
    font-size: 60px;
  }
}
</style>